.form-dropdown
{
    margin-top: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    width: 100%;
    height: 44px;

    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 6px;
    
    box-shadow: 0 10px 32px #0000000f;
    
    background-color: #ffffff;
    background-image: url('/public/image/icon_dropdown.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right 12px top 50%;
}

.form-dropdown:hover
{
    cursor: pointer;
    
    border-color: #8cc63f;
}

.form-error
{
    color: #d73030;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-align: left;
}

.form-label
{
    margin-top: 24px;

    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.form-label-small
{
    margin-top: 24px;
    margin-bottom: 0px;
    padding-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.form-label-small-required
{
    display: inline-block;
    margin-left: 4px;

    color: #ff0000;
}

.form-loading-container
{
    margin: auto;

    width: 48px;
    height: 64px;
}

.form-loading
{
    width: 48px;
    height: 48px;

    background-image: url('/public/image/icon_loading.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 48px 48px;

    animation-name: form-loading-spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes form-loading-spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.form-link
{
    margin-top: 16px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
    text-decoration: underline;
    -webkit-font-smoothing: antialiased;

    color: #8cc63f;
}

.form-link:hover
{
    cursor: pointer;
}

.form-number-button-left
{
    flex-grow: 1;

    height: 44px;

    background-color: #ffffff;

    font-size: 28px;
    line-height: 32px;
    font-weight: 300;
    text-align: center;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-right-color: #ebebeb;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}   

.form-number-button-left:hover
{
    cursor: pointer;

    border-color: #96d248
}

.form-number-button-right
{
    flex-grow: 1;

    height: 44px;

    background-color: #ffffff;

    font-size: 28px;
    line-height: 32px;
    font-weight: 300;
    text-align: center;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-left-color: #ebebeb;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}   

.form-number-button-right:hover
{
    cursor: pointer;

    border-color: #96d248
}

.form-number-container
{
    align-items: flex-end;
    display: flex;
	flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;

    margin-top: 23px;
    width: 100%;
    height: 44px;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    box-shadow: 0 10px 32px #0000000f;
}

.form-number-label
{
    flex-grow: 4;
    height: 44px;

    background-color: #ffffff;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 0px;
}

.form-number-label:hover
{
    cursor: pointer;

    border-color: #96d248;
}

.form-number-label-input
{
    width: 100%;
    height: 100%;

    text-align: center;

    border: none;
}

.form-switch .form-check-input:checked
{
    background-color: #8cc63f;
    border-color: #8cc63f;
}

.form-radio-hidden
{
    position: fixed;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.form-radio-preferencetile-container
{
    width: 100%;
    margin-top: 23px;
}

.form-radio-preferencetile
{
    display: block;

    margin-bottom: 16px;
    padding: 20px;

    background-color: #ffffff;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 6px;

    box-shadow: 0 10px 32px #0000000f;

    -webkit-transition: transform 0.15s linear;
    -ms-transition: transform 0.15s linear;
    transition: transform 0.15s linear;
}

.form-radio-preferencetile:hover
{
    cursor: pointer;

    transform: scale(1.0675);
}

.form-radio-preferencetile-selected
{
    border-color: #96d248;
}

.form-radio-preferencetile-title-container
{
    align-items: center;
    display: flex;
    margin-bottom: 8px;
}

.form-radio-preferencetile-title-icon
{
    min-height: 36px;
    min-width: 36px;

    background-repeat: no-repeat;
    background-size: contain;
}

.form-radio-preferencetile-title
{
    display: inline-block;
    
    margin-left: 12px;
    margin-right: 6px;

    color: #000000;

    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
    text-align: left;
}

.form-radio-preferencetile-recommend
{
    display: inline-block;
    margin-left: 48px;
    padding: 3px;

    color: #ffffff;
    background-color: #8cc63f;

    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    text-align: left;
    vertical-align: bottom;

    border-style: solid;
    border-width: 1px;
    border-color: #8cc63f;
    border-radius: 3px;
}

.form-radio-preferencetile-recommend-yes
{
    display: flex;
    align-items: center;

    color: #8cc63f;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

.form-radio-preferencetile-recommend-yes-icon
{
    display: inline-block;

    width: 28px;
    height: 28px;
    margin-left: 4px;
    margin-right: 16px;

    background-image: url('/public/image/icon_accept.svg');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.form-radio-preferencetile-recommend-no
{
    display: flex;
    align-items: center;

    color: #da2d2d;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

.form-radio-preferencetile-recommend-no-icon
{
    display: inline-block;

    width: 28px;
    height: 28px;
    margin-left: 4px;
    margin-right: 16px;

    background-image: url('/public/image/icon_decline.svg');
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position: center;
}

.form-radio-preferencetile-footer
{
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    padding: 20px;

    border-bottom-right-radius: 4px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #e9e9e9;
}

.form-radio-preferencetile-text
{
    margin-bottom: 5px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

.form-radio-tile
{
    background-color: #ffffff;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 6px;

    box-shadow: 0 10px 32px #0000000f;

    -webkit-transition: transform 0.15s linear;
    -ms-transition: transform 0.15s linear;
    transition: transform 0.15s linear;
}

.form-radio-tile:hover
{
    cursor: pointer;

    transform: scale(1.125);
}

.form-radio-tile-container
{
    align-items: flex-end;
    display: flex;

    justify-content: space-evenly;
	flex-wrap: nowrap;
    column-gap: 16px;

    margin-top: 23px;
}

.form-radio-tile-selected
{
    border-color: #96d248;
}

.form-radio-tile-label
{
    display: table;

    width: 100%;
    height: 64px;

    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
}

.form-radio-text-container
{
    margin-top: 23px;

    width: 100%;
}

.form-radio-text-label
{
    padding-top: 2px;
    padding-bottom: 2px;
    width: 100%;
}

.form-radio-text-input
{
    display: inline-block;

    padding-left: 8px;
}

.form-radio-tile-label-text
{
    display: table-cell;
   
    padding-left: 12px;
    padding-right: 12px;

    color: #000000;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
}

.form-radio-tile-icon
{
    height: 48px;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-submit
{
    margin-top: 48px;
    margin-bottom: 48px;

    width: 100%;
    height: 44px;

    font-weight: 700;

    color: #ffffff;
    background-color: #8cc63f;

    border: none;
    border-radius: 6px;

    -webkit-transition: background-color 0.15s linear;
    -ms-transition: background-color 0.15s linear;
    transition: background-color 0.15s linear;
}

.form-submit:hover
{
    background-color: #96d248;
}

.form-submit-overview
{
    margin-top: 0px;
    margin-bottom: 24px;

    width: 100%;
    height: 44px;

    font-weight: 700;

    color: #ffffff;
    background-color: #8cc63f;

    border: none;
    border-radius: 6px;

    -webkit-transition: background-color 0.15s linear;
    -ms-transition: background-color 0.15s linear;
    transition: background-color 0.15s linear;
}

.form-submit-overview:hover
{
    background-color: #96d248;
}

.form-text
{
    margin-top: 5px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    width: 100%;
    height: 44px;

    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;

    border: none;
    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 6px;

    box-shadow: 0 10px 32px #0000000f;
}

.form-text:hover
{
    border-color: #8cc63f;
}

.form-toggle
{
    margin-right: 8px;

    width: 48px !important;
    height: 24px;
}