
.status-container
{
    padding-bottom: 3px;
}

.status-overlay
{
    min-height: 8px;

    background-color: #8cc63f;

    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    -webkit-transition: width 0.15s ease;
    -moz-transition: width 0.15s ease;
    -o-transition: width 0.15s ease;
    transition: width 0.15s ease;
}

.status-section
{
    min-height: 8px;

    background-color: #cbcbcb;
}